export default {
	patternsBegin(state) {
		state.loading = true
	},
	patternsSuccess(state, data) {
		state.patterns = data
		state.loading = false
	},
	patternsErr(state, err) {
		state.patterns = []
		state.loading = false
		state.error = err
	},

	patternSuccess(state, data) {
		state.pattern = data
		state.loading = false
	},
	patternErr(state, err) {
		state.pattern = null
		state.loading = false
		state.error = err
	}
}
