const state = {
	servertime: {
		now: null,
		timezone: 'America/Sao_Paulo',
		format: 'YYYY-MM-DD HH:mm:ss'
	},
	applications_search: {
		application_name_search_string: null,
		application_school_search: null,
		application_status_search: 'todos',
		application_year_search: null,
		application_start_search: null,
		application_end_search: null,
		application_types_search: ['prova', 'simulado', 'simuladoaberto', 'vestibular', 'atividaderedacao']
	},
	exams_search: {
		exam_name_search_string: '',
		exam_status_search: 'todos',
		exam_structure_search: 0
	},
	testsbank_search: {
		year_search: [],
		codigo_alfred_search: '',
		codigo_superpro_search: '',
		status_search: [],
		instituicao_search: 0,
		questions_types_search: ['alternativa', 'dissertativa', 'redacao'],
		dificuldade_informada_search: 0,
		dificuldade_auferida_search: 0,
		disciplina_search: [],
		statement_text_search: '',
		alternatives_text_search: '',
		competences_search: [],
		skills_search: [],
		subject_search: []
	},

	pusherstatus: null,

	global_filter: {
		escolaId: null,
		escolaName: null,
		disciplinaId: null,
		disciplinaName: null,
		turmaId: null,
		turmaName: null
	},

	analytics: {
		activeAlfredUsers: [],
		userAlfredMovement: { user: null, side: null },
		updated_at: null
	},

	awsauth: { awsAuthSignedIn: false, awsJwtToken: null },

	school_id: null
}

const mutations = {
	updateNow(state, { key, value }) {
		state.servertime[key] = value
	},
	updateApplicationsSearch(state, { key, value }) {
		state.applications_search[key] = value
	},
	updateExamsSearch(state, { key, value }) {
		state.exams_search[key] = value
	},
	updateTestsbankSearch(state, { key, value }) {
		state.testsbank_search[key] = value
	},
	updatePusherStatus(state, value) {
		state.pusherstatus = value
	},
	updateGlobalFilter(state, { escolaId, escolaName, disciplinaId, disciplinaName, turmaId, turmaName }) {
		state.global_filter.escolaId = escolaId
		state.global_filter.escolaName = escolaName
		state.global_filter.disciplinaId = disciplinaId
		state.global_filter.disciplinaName = disciplinaName
		state.global_filter.turmaId = turmaId
		state.global_filter.turmaName = turmaName
	},
	updateAnalytics(state, { key, value }) {
		state.analytics[key] = value
	},
	updateAwsAuth(state, { key, value }) {
		state.awsauth[key] = value
	},
	updateAnalyticsPushUser(state, user) {
		state.analytics.activeAlfredUsers.push(user)
	},
	updateAnalyticsSpliceUser(state, payload) {
		var userindex = state.analytics.activeAlfredUsers.findIndex((usr) => usr.id == payload.userId)
		state.analytics.activeAlfredUsers.splice(userindex, 1)
	},
	updateAnalyticsNavigation(state, payload) {
		var userindex = state.analytics.activeAlfredUsers.findIndex((usr) => usr.id == payload.userId)
		if (userindex) {
			try {
				state.analytics.activeAlfredUsers[userindex].location = payload.to
				state.analytics.activeAlfredUsers[userindex].updated_at = payload.now
				state.analytics.updated_at = payload.now
			} catch (error) {
				// console.error(error);
			}
		}
	},
	updateSchoolId(state, value) {
		state.school_id = value
	}
}
const getters = {
	servertime: (state) => state.servertime,
	applicationsSearch: (state) => state.applications_search,
	examsSearch: (state) => state.exams_search,
	testsbankSearch: (state) => state.testsbank_search,
	pusherstatus: (state) => state.pusherstatus,
	globalFilter: (state) => state.global_filter,
	analytics: (state) => state.analytics,
	awsauth: (state) => state.awsauth,
	schoolId: (state) => state.school_id
}

const actions = {}

export default {
	state,
	mutations,
	getters,
	actions
}
