import 'es6-promise/auto'
import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import auth from './auth'
import alfred from './alfred'
import trails from './modules/trails/actionCreator'
import patterns from './modules/patterns/actionCreator'
import escolas from './escolas'

Vue.use(Vuex)

function addDays(noOfDays) {
	return noOfDays * 24 * 60 * 60 * 1000
}

//=======vuex store start===========
const store = new Vuex.Store({
	state: {
		left_open: true,
		right_open: false,
		preloader: true,
		logoutproccess: false,
		site_name: 'Alfred',
		page_title: null,
		show_page_title: true,
		// Add your application keys
		gmap_key: 'AIzaSyBTnQCx3FXEnfWPPWTKAwIxt6wSjAn_8ug',
		openWeather_key: 'c00194f61244d2b33b863bff6d94e663'
	},
	mutations: mutations,
	modules: {
		auth,
		alfred,
		trails,
		patterns,
		escolas
	}
})
//=======vuex store end===========
export default store
