let mutations = {
	left_menu(state, option) {
		switch (option) {
			case 'open':
				state.left_open = true
				break

			case 'close':
				state.left_open = false
				break
			case 'toggle':
				state.left_open = !state.left_open
				break
			default:
				state.left_open = true
				break
		}

		if (state.left_open) {
			document.getElementsByTagName('body')[0].classList.remove('left-hidden')
		} else {
			document.getElementsByTagName('body')[0].classList.add('left-hidden')
		}
	},
	rightside_bar(state, option) {
		switch (option) {
			case 'open':
				state.right_open = true
				break

			case 'close':
				state.right_open = false
				break
			case 'toggle':
				state.right_open = !state.right_open
				break
			default:
				state.right_open = true
				break
		}
		if (state.right_open) {
			document.getElementsByTagName('body')[0].classList.add('sidebar-right-opened')
			document.getElementById('right-side').classList.add('right-active')
		} else {
			document.getElementsByTagName('body')[0].classList.remove('sidebar-right-opened')
			document.getElementById('right-side').classList.remove('right-active')
		}
	},
	changeLogoutproccess(state, option) {
		state.logoutproccess = option
	},
	routeChange(state, loader) {
		if (loader == 'start') {
			state.preloader = true
		} else if (loader == 'end') {
			state.preloader = false
		}

		switch (loader) {
			case 'start':
				state.preloader = true

				break
			case 'end':
				state.preloader = false
				break
		}
	},
	changePageTitle(state, title) {
		state.page_title = title
		document.title = title + ' - ' + state.site_name
	},
	changePreloader(state, { key, value }) {
		state.preloader = value
	},
	secondHeader(state, option) {
		switch (option) {
			case 'close':
				state.show_page_title = false
				break
			case 'open':
				state.show_page_title = true
				break

			default:
				state.show_page_title = true

				break
		}
	}
}
export default mutations
