<template>
	<div id="app">
		<WhatsappButton />
		<router-view />
	</div>
</template>

<script>
	import Vue from 'vue'
	import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
	import ToggleButton from 'vue-js-toggle-button'
	import WhatsappButton from './common/whatsapp-button.vue'

	Vue.use(ToggleButton)
	Vue.use(BootstrapVue)
	Vue.use(BootstrapVueIcons)
	export default {
		name: 'app',
		components: {
			WhatsappButton
		}
	}
</script>

<style lang="scss" src="./assets/sass/bootstrap/bootstrap.scss"></style>
<style src="bootstrap-vue/dist/bootstrap-vue.css"></style>
