import ApiService from '../../../common/api.service'
import mutations from './mutations'
import sampledata from './data.json'

const state = () => ({
	loading: false,
	error: null,
	trails: [],
	trail: null
})

const actions = {
	async getTrails({ commit }) {
		try {
			commit('trailsBegin')
			ApiService.get('/trails/getTrails').then((response) => {
				if (response.data.status == 'success') {
					commit('trailsSuccess', response.data.trails)
				} else {
					commit('trailsErr', err)
				}
			})
		} catch (err) {
			commit('trailsErr', err)
		}
	},

	async getTrail({ commit }, trailId) {
		try {
			commit('trailsBegin')
			if (trailId) {
				ApiService.get('/trails/getTrail/' + trailId).then((response) => {
					if (response.data.status == 'success') {
						commit('trailSuccess', response.data.trail)
					} else {
						commit('trailErr', err)
					}
				})
			} else {
				commit('trailSuccess', {
					id: null,
					name: null,
					status: 'inactive',
					type: null,
					application_pattern_id: 1,
					created_at: null,
					updated_at: null,
					trail_items: []
				})
			}
		} catch (err) {
			commit('trailErr', err)
		}
	},
	async createTrail({ commit }, trail) {
		try {
			commit('trailsBegin')

			ApiService.post('/trails/postTrail/', trail).then((response) => {
				if (response.data.status == 'success') {
					commit('trailSuccess', response.data.trail)
					this.dispatch('getTrails')
				} else {
					commit('trailErr', err)
				}
			})
		} catch (err) {
			commit('trailErr', err)
		}
	}
}

export default {
	namespaced: false,
	state,
	actions,
	mutations
}
