const layout = [
	{
		path: 'dashboard',
		name: 'dashboard',
		component: (resolve) => require(['../components/pages/home/index'], resolve),
		meta: {
			title: 'Dashboard'
		}
	},
	{
		path: 'privacypolicy',
		name: 'privacy-policy',
		component: (resolve) => require(['../components/pages/legal/users/privacypolicy'], resolve),
		meta: {
			title: 'Política de Privacidade'
		}
	},
	{
		path: 'termsofuse',
		name: 'terms-of-use',
		component: (resolve) => require(['../components/pages/legal/users/termsofuse'], resolve),
		meta: {
			title: 'Termos de Uso'
		}
	},
	{
		path: 'users',
		name: 'users',
		component: (resolve) => require(['../components/pages/users/users'], resolve),
		meta: {
			title: 'Usuários'
		}
	},
	{
		path: 'user/:userId',
		name: 'user',
		component: (resolve) => require(['../components/pages/users/user'], resolve),
		meta: {
			title: 'Usuário'
		},
		props: true
	},
	{
		path: 'users-import',
		name: 'import_users',
		component: (resolve) => require(['../components/pages/users/import-users'], resolve),
		meta: {
			title: 'Usuários'
		},
		props: true
	},
	{
		path: 'students',
		name: 'students',
		component: (resolve) => require(['../components/pages/users/users'], resolve),
		meta: {
			title: 'Alunos'
		}
	},
	{
		path: 'schools',
		name: 'schools',
		component: (resolve) => require(['../components/pages/schools/schools'], resolve),
		meta: {
			title: 'Escolas'
		}
	},
	// {
	//   path: "edit_school/:schoolId",
	//   name: "edit_school",
	//   component: (resolve) => require(["../components/pages/schools/edit_school"], resolve),
	//   meta: {
	//     title: "Editar escola",
	//   },
	//   name: "Editar Escola",
	//   props: true,
	// },
	{
		path: 'school/:schoolId',
		name: 'school',
		component: (resolve) => require(['../components/pages/schools/school'], resolve),
		meta: {
			title: 'Escola'
		},
		props: true
	},
	{
		path: 'school/:schoolId/teachers',
		name: 'school_teachers',
		component: (resolve) => require(['../components/pages/schools/school-teachers'], resolve),
		meta: {
			title: 'Professores'
		},
		props: true
	},
	{
		path: 'school/:schoolId/class/:schoolClassId',
		name: 'school_class',
		component: (resolve) => require(['../components/pages/schools/school-class'], resolve),
		meta: {
			title: 'Turma'
		},
		props: true
	},
	{
		path: 'school/:schoolId/class/:schoolClassId/answerssheet',
		name: 'school_class_answers_sheet',
		component: (resolve) => require(['../components/pages/schools/school-class-answers-sheet'], resolve),
		meta: {
			title: 'Turma - Geração de Folha de Respostas '
		},
		props: true
	},
	{
		path: 'school/imports/users/:schoolId',
		name: 'school_import_users',
		component: (resolve) => require(['../components/pages/schools/school-import-users'], resolve),
		meta: {
			title: 'Escola - Importação de alunos'
		},
		props: true
	},
	{
		path: 'testsbank/:examId?',
		name: 'testsbank',
		component: (resolve) => require(['../components/pages/testsbank/testsbank'], resolve),
		meta: {
			title: 'Banco de Questões'
		},
		props: true
	},
	{
		name: 'question',
		path: 'question/:questionId',
		component: (resolve) => require(['../components/pages/testsbank/question'], resolve),
		meta: {
			title: 'Questão'
		},
		props: true
	},
	{
		name: 'coordinator-applications',
		path: 'coord/applications',
		component: (resolve) =>
			require(['../components/pages/applications/coordinator/applications'], resolve),
		meta: {
			title: 'Aplicações'
		}
	},
	{
		name: 'coordinator-application',
		path: 'coord/application/:applicationId',
		component: (resolve) =>
			require(['../components/pages/applications/coordinator/application'], resolve),
		meta: {
			title: 'Aplicação'
		},
		props: true
	},
	{
		name: 'disciplina-question-analysis-coordenador',
		path: 'coord/application/:applicationId/disciplina/:disciplinaId/',
		component: (resolve) =>
			require([
				'../components/pages/applications/coordinator/disciplina-question-analysis-coordenador.vue'
			], resolve),
		meta: {
			title: 'Analise de questões por disciplina'
		},
		props: true
	},
	{
		name: 'applications',
		path: 'applications/:querymode',
		component: (resolve) => require(['../components/pages/applications/applications'], resolve),
		meta: {
			title: 'Aplicações'
		},
		props: true
	},
	{
		name: 'students-applications',
		path: 'students/applications',
		component: (resolve) => require(['../components/pages/applications/students/applications'], resolve),
		meta: {
			title: 'Provas e Simulados'
		}
	},
	{
		name: 'application',
		path: 'application/:applicationId',
		component: (resolve) => require(['../components/pages/applications/application'], resolve),
		meta: {
			title: 'Aplicação'
		},
		props: true
	},
	{
		path: 'application/scope/:applicationId',
		name: 'application_scope',
		component: (resolve) => require(['../components/pages/applications/application-scope'], resolve),
		meta: {
			title: 'Escopo da Aplicação'
		},
		props: true
	},
	{
		path: 'application/enrollment-options/:applicationId',
		name: 'application_enrollment_options',
		component: (resolve) =>
			require(['../components/pages/applications/application-enrollment-options'], resolve),
		meta: {
			title: 'Opções de Inscrição da Aplicação'
		},
		props: true
	},
	{
		path: 'application/imports/answers-grades/:applicationId',
		name: 'application_import_answers_grades',
		component: (resolve) =>
			require(['../components/pages/applications/application-import-answers-grades'], resolve),
		meta: {
			title: 'Aplicação - Importação de respostas e notas'
		},
		props: true
	},
	{
		path: 'application/imports/answers-files/:applicationId',
		name: 'application_import_answers_files',
		component: (resolve) =>
			require(['../components/pages/applications/application-import-answers-files'], resolve),
		meta: {
			title: 'Aplicação - Importação de arquivos em massa'
		},
		props: true
	},
	{
		path: 'application/update/users/questions/:applicationId',
		name: 'application_adjust_user_questions',
		component: (resolve) =>
			require(['../components/pages/applications/application-adjust-user-questions'], resolve),
		meta: {
			title: 'Aplicação - Atualização das questões dos usuários'
		},
		props: true
	},
	{
		path: 'application/update/users/classes/:applicationId',
		name: 'application_adjust_user_classes',
		component: (resolve) =>
			require(['../components/pages/applications/application-adjust-user-classes'], resolve),
		meta: {
			title: 'Aplicação - Atualização das turmas dos usuários'
		},
		props: true
	},
	{
		path: 'application/scope/users/:applicationId',
		name: 'application_scope_users',
		component: (resolve) =>
			require(['../components/pages/applications/application-scope-users'], resolve),
		meta: {
			title: 'Usuários do Escopo da Aplicação'
		},
		props: true
	},
	{
		path: 'application/:applicationId/notify/sms/users',
		name: 'application_notify_via_sms_users',
		component: (resolve) =>
			require(['../components/pages/applications/application-notify-via-sms-users'], resolve),
		meta: {
			title: 'Notificar Usuários da Aplicação via SMS'
		},
		props: true
	},
	{
		path: 'application/deleted/users/:applicationId',
		name: 'application_deleted_users',
		component: (resolve) =>
			require(['../components/pages/applications/application-deleted-users'], resolve),
		meta: {
			title: 'Usuários do Excluídos da Aplicação'
		},
		props: true
	},
	{
		path: 'application/:applicationId/questions/writing/:questionId',
		name: 'application_writing_questions',
		component: (resolve) =>
			require(['../components/pages/applications/application-writing-questions'], resolve),
		meta: {
			title: 'Questões da Aplicação'
		},
		props: true
	},
	{
		path: 'application/:applicationId/questions/writing/:questionId/analysis/:answerId',
		name: 'application_writing_questions_analysis',
		component: (resolve) =>
			require(['../components/pages/applications/application-writing-questions-analysis'], resolve),
		meta: {
			title: 'Análise da Redação'
		},
		props: true
	},
	{
		path: 'application/:applicationId/questions/essay/:questionId',
		name: 'application_essay_questions',
		component: (resolve) =>
			require(['../components/pages/applications/application-essay-questions'], resolve),
		meta: {
			title: 'Questões da Aplicação'
		},
		props: true
	},
	{
		path: 'application/:applicationId/:phaseId',
		name: 'answer_application',
		component: (resolve) => require(['../components/pages/applications/answer-application'], resolve),
		meta: {
			title: 'Responder Aplicação'
		},
		props: true
	},
	{
		path: 'application/question/:applicationId/:phaseId',
		name: 'answer_application_question_by_question',
		component: (resolve) =>
			require([
				'../components/pages/applications/answer-application-question-by-question.vue'
			], resolve),
		meta: {
			title: 'Responder Aplicação'
		},
		props: true
	},

	{
		path: 'application/:applicationId/reports/user/:userId',
		name: 'application_user_report',
		component: (resolve) =>
			require(['../components/pages/applications/reports/application-user-report'], resolve),
		meta: {
			title: 'Aplicação - boletim do Aluno'
		},
		props: true
	},

	{
		name: 'exams',
		path: 'exams',
		component: (resolve) => require(['../components/pages/exams/exams'], resolve),
		meta: {
			title: 'Avaliações'
		}
	},
	{
		path: 'exam/:examId',
		name: 'exam',
		component: (resolve) => require(['../components/pages/exams/exam'], resolve),
		meta: {
			title: 'Avaliação'
		},
		props: true
	},
	{
		name: 'structures',
		path: 'structures',
		component: (resolve) => require(['../components/pages/structures/structures'], resolve),
		meta: {
			title: 'Estruturas'
		}
	},
	{
		path: 'structure/:structureId',
		name: 'structure',
		component: (resolve) => require(['../components/pages/structures/structure'], resolve),
		meta: {
			title: 'Estrutura'
		},
		props: true
	},
	{
		path: 'my-account',
		name: 'my-account',
		component: (resolve) => require(['../components/pages/users/my-account'], resolve),
		meta: {
			title: 'Minha Conta'
		}
	},
	{
		path: 'my-exports',
		name: 'my-exports',
		component: (resolve) => require(['../components/pages/users/my-exports'], resolve),
		meta: {
			title: 'Minhas Exportações'
		}
	},
	{
		path: 'my-classes',
		name: 'my-classes',
		component: (resolve) => require(['../components/pages/schools/my-classes'], resolve),
		meta: {
			title: 'Minhas Turmas'
		}
	},

	// Felipe Page
	{
		path: 'my-classes/turma/:turmaId',
		name: 'Alunos',
		component: (resolve) => require(['../components/pages/schools/coordinator/turma'], resolve),
		meta: {
			title: 'Meus Alunos'
		},
		props: true
	},
	{
		path: 'instituicoes/:instituicoesId/carreira/:carreiraId',
		name: 'Instituicoes',
		component: (resolve) => require(['../components/pages/users/instituicoes'], resolve),
		meta: {
			title: 'Instituições'
		},
		props: true
	},
	{
		path: 'carreiras/:carreiraId',
		name: 'Carreira',
		component: (resolve) => require(['../components/pages/users/carreiras'], resolve),
		meta: {
			title: 'Carreira'
		},
		props: true
	},

	//Fim

	{
		path: 'my-students',
		name: 'my-students',
		component: (resolve) => require(['../components/pages/schools/my-students'], resolve),
		meta: {
			title: 'Meus Alunos'
		}
	},
	{
		path: 'vestibulares',
		name: 'my-vestibulares',
		component: (resolve) => require(['../components/pages/students/my-vestibulares'], resolve),
		meta: {
			title: 'Meus Vestibulares'
		},
		props: true
	},
	{
		path: 'simulados',
		name: 'my-simulados',
		component: (resolve) => require(['../components/pages/students/my-simulados'], resolve),
		meta: {
			title: 'Meus Simulados'
		},
		props: true
	},
	{
		path: 'provas',
		name: 'my-provas',
		component: (resolve) => require(['../components/pages/students/my-provas'], resolve),
		meta: {
			title: 'Minhas Provas'
		},
		props: true
	},
	{
		path: 'performance/:userId?',
		name: 'performance',
		component: (resolve) => require(['../components/pages/students/performance'], resolve),
		meta: {
			title: 'Análise de Performance'
		},
		props: true
	},
	{
		path: 'responsible/performance/:userId?',
		name: 'responsible-performance',
		component: (resolve) => require(['../components/pages/responsible/performance'], resolve),
		meta: {
			title: 'Análise de Performance'
		},
		props: true
	},
	{
		path: 'responsible/user-perfil/:userId?',
		name: 'responsible-studant-view',
		component: (resolve) =>
			require(['../components/pages/responsible/responsible-studant-view'], resolve),
		meta: {
			title: 'Análise de Performance'
		},
		props: true
	},
	{
		path: 'performance/:userId/:modalidade/:disciplinaId/details',
		name: 'performance-details',
		component: (resolve) => require(['../components/pages/students/performance-details'], resolve),
		meta: {
			title: 'Análise de Performance por Disciplina'
		},
		props: true
	},
	{
		path: 'responsible/performance/:userId/:disciplinaId/details',
		name: 'responsible-performance-details',
		component: (resolve) => require(['../components/pages/responsible/performance-details'], resolve),
		meta: {
			title: 'Análise de Performance por Disciplina'
		},
		props: true
	},
	{
		path: 'performance/:userId/:disciplinaId/:modalidade/:assuntoId/details',
		name: 'performance-details-questions',
		component: (resolve) =>
			require(['../components/pages/students/performance-details-questions'], resolve),
		meta: {
			title: 'Questões respondidas do assunto'
		},
		props: true
	},
	{
		path: 'responsible/performance/:userId/:disciplinaId/:assuntoId/details',
		name: 'responsible-performance-details-questions',
		component: (resolve) =>
			require(['../components/pages/responsible/performance-details-questions'], resolve),
		meta: {
			title: 'Questões respondidas do assunto'
		},
		props: true
	},
	{
		path: 'students-performance',
		name: 'students-performance',
		component: (resolve) => require(['../components/pages/students/students-performance'], resolve),
		meta: {
			title: 'Análise de Performance Geral'
		},
		props: true
	},

	{
		path: 'evolution/:userId',
		name: 'evolution',
		component: (resolve) => require(['../components/pages/students/evolution'], resolve),
		meta: {
			title: 'Evolução'
		},
		props: true
	},
	{
		path: 'responsible/evolution/:userId',
		name: 'responsible-evolution',
		component: (resolve) => require(['../components/pages/responsible/evolution'], resolve),
		meta: {
			title: 'Evolução'
		},
		props: true
	},
	{
		path: 'admin/legal',
		name: 'admin-legal',
		component: (resolve) => require(['../components/pages/legal/admin/index'], resolve),
		meta: {
			title: 'Documentos Legais'
		}
	},
	{
		path: 'admin/answersheet/:applicationId?',
		name: 'admin-answersheet',
		component: (resolve) =>
			require(['../components/pages/applications/reports/answers_sheet.vue'], resolve),
		meta: {
			title: 'Folhas de Respostas'
		},
		props: true
	},
	{
		path: 'admin/newanswersheet/',
		name: 'new-admin-answersheet',
		component: (resolve) => require(['../components/pages/admin/new-answers-sheet.vue'], resolve),
		meta: {
			title: 'Folhas de Respostas'
		},
		props: true
	},
	{
		path: 'admin/mergeusers',
		name: 'admin-mergeusers',
		component: (resolve) => require(['../components/pages/admin/mergeusers.vue'], resolve),
		meta: {
			title: 'Unificar Usuários'
		},
		props: true
	},
	{
		path: 'admin/class-maintenance',
		name: 'admin-class-maintenance',
		component: (resolve) => require(['../components/pages/admin/class-maintenance.vue'], resolve),
		meta: {
			title: 'Manutenção de Turmas'
		},
		props: true
	},
	{
		path: 'service',
		name: 'service',
		component: (resolve) => require(['../components/pages/service/index'], resolve),
		meta: {
			title: 'Atendimento'
		},
		props: true
	},
	{
		path: 'service-aluno',
		name: 'service-aluno',
		component: (resolve) => require(['../components/pages/service/index-aluno'], resolve),
		meta: {
			title: 'Atendimento'
		},
		props: true
	},

	{
		path: 'all-services-student/:userId',
		name: 'all-services-student',
		component: (resolve) => require(['../components/pages/service/all-services-student'], resolve),
		meta: {
			title: 'Atendimento(s) do estudante'
		},
		props: true
	},

	{
		path: 'service-secretario',
		name: 'service-secretario',
		component: (resolve) => require(['../components/pages/service/index-secretario'], resolve),
		meta: {
			title: 'Atendimento'
		},
		props: true
	},
	{
		path: 'schools/new',
		name: 'create-school',
		component: (resolve) => require(['../components/pages/schools/create-school'], resolve),
		meta: {
			title: 'Nova Escola'
		}
	},
	{
		path: '/app/users/gerencia-metas',
		name: 'Metas',
		component: (resolve) => require(['../components/pages/users/gerencia-metas'], resolve),
		meta: {
			title: 'Gerência Metas'
		},
		props: true
	},
	{
		path: 'service/:serviceId',
		name: 'service-create',
		component: (resolve) => require(['../components/pages/service/create'], resolve),
		meta: {
			title: 'Atendimento'
		},
		props: true
	},
	{
		path: 'service/attend/:serviceId',
		name: 'service-attend',
		component: (resolve) => require(['../components/pages/service/attend'], resolve),
		meta: {
			title: 'Atendimento'
		},
		props: true
	},
	{
		path: 'service/attend-aluno/:serviceId',
		name: 'service-attend-aluno',
		component: (resolve) => require(['../components/pages/service/attend-aluno'], resolve),
		meta: {
			title: 'Atendimento'
		},
		props: true
	},
	{
		path: 'aplicacoes-minhas-turmas/:turmaId',
		name: 'Aplicacoes',
		component: (resolve) =>
			require(['../components/pages/schools/coordinator/turma-application'], resolve),
		meta: {
			title: 'Aplicações'
		},
		props: true
	},
	{
		path: 'coordinator/studants/application/:applicationId/turma/:turmaId',
		name: 'alunos-presentes',
		component: (resolve) =>
			require(['../components/pages/schools/coordinator/alunos-presentes'], resolve),
		meta: {
			title: 'Alunos Presentes'
		},
		props: true
	},
	{
		path: 'study-planning/:planId',
		name: 'study-planning',
		component: (resolve) => require(['../components/pages/students/study-planning'], resolve),
		meta: {
			title: 'Planejamento de Estudos'
		},
		props: true
	},
	{
		path: 'trails',
		name: 'trails',
		component: (resolve) => require(['../components/pages/trails/index'], resolve),
		meta: {
			title: 'Trilhas'
		},
		props: true
	},
	{
		path: 'trails/trail/:trailId',
		name: 'trails-trail',
		component: (resolve) => require(['../components/pages/trails/trail'], resolve),
		meta: {
			title: 'Trilha'
		},
		props: true
	},
	{
		path: 'trails/create/:trailId?',
		name: 'trails-create',
		component: (resolve) => require(['../components/pages/trails/create'], resolve),
		meta: {
			title: 'Criar Trilha'
		},
		props: true
	},
	{
		path: 'support',
		name: 'support',
		component: (resolve) => require(['../components/pages/support/index'], resolve),
		meta: {
			title: 'Suporte'
		},
		props: true
	},
	{
		name: 'coordinator-aplicacao',
		path: 'coord/aplicacao/:applicationId',
		component: (resolve) => require(['../components/pages/schools/coordinator/aplicacao'], resolve),
		meta: {
			title: 'Aplicação'
		},
		props: true
	},
	{
		name: 'coordinator-user-desempenho',
		path: 'coord/aplicacao/:applicationId/user/:userId/desempenho/',
		component: (resolve) =>
			require(['../components/pages/schools/coordinator/desempenho_assunto'], resolve),
		meta: {
			title: 'Desempenho'
		},
		props: true
	},

	{
		name: 'coordinator-boletim',
		path: 'coord/boletim/user/:userId/application/:applicationId',
		component: (resolve) => require(['../components/pages/schools/coordinator/boletim'], resolve),
		meta: {
			title: 'Boletim'
		},
		props: true
	},
	{
		path: 'analytics',
		name: 'analytics',
		component: (resolve) => require(['../components/pages/analytics/analytics'], resolve),
		meta: {
			title: 'Alfred Analytics'
		},
		props: true
	},
	{
		name: 'transferirUsers',
		path: 'avaliacao/transferir/users',
		component: (resolve) =>
			require(['../components/pages/applications/appilication-move-or-copy-users'], resolve),
		meta: {
			title: 'Transferir Usuários'
		},
		props: true
	},
	{
		name: 'aws-auth-page',
		path: 'aws-auth',
		component: (resolve) => require(['../components/pages/auth/aws-auth-page'], resolve),
		meta: {
			title: 'AWS Authentication'
		},
		props: true
	},
	{
		path: 'books',
		name: 'books',
		component: (resolve) => require(['../components/pages/content/index'], resolve),
		meta: {
			title: 'Apostilas'
		},
		props: true
	},

	{
		path: 'disciplinas-conteudos',
		name: 'contents-disciplinas',
		component: (resolve) => require(['../components/pages/content/indexContents'], resolve),
		meta: {
			title: 'Disciplinas'
		},
		props: true
	},

	{
		path: 'contents-disciplinas/disciplina/:disciplinaId/assuntos',
		name: 'assuntos',
		component: (resolve) => require(['../components/pages/content/assuntos.vue'], resolve),
		meta: {
			title: 'Assuntos dos conteúdos'
		},
		props: true
	},
	{
		path: 'contents-disciplinas/disciplina/:disciplinaId/assuntos/:assuntoId/contents',
		name: 'contents',
		component: (resolve) => require(['../components/pages/content/contents.vue'], resolve),
		meta: {
			title: 'Conteúdos'
		},
		props: true
	},

	{
		path: 'createcontent',
		name: 'create-content',
		component: (resolve) =>
			require(['../components/pages/content/components/edit-conteudos.vue'], resolve),
		meta: {
			title: 'Editar conteúdo'
		},
		props: true
	},
	{
		path: 'editresolution',
		name: 'EditarResolucao',
		component: (resolve) => require(['../components/pages/testsbank/edit-resolucao.vue'], resolve),
		meta: {
			title: 'Editar resolução'
		},
		props: true
	},
	{
		path: 'readcontent/:contentId',
		name: 'read-content',
		component: (resolve) => require(['../components/pages/content/components/ver-conteudo.vue'], resolve),
		meta: {
			title: 'Conteúdo'
		},
		props: true
	},

	{
		path: '/turma/:turmaId/',
		name: 'performanceByDisciplinaInTurma',
		component: (resolve) =>
			require(['../components/pages/schools/coordinator/performance-by-disciplina-turma.vue'], resolve),
		meta: {
			title: 'Performance por disciplina na turma'
		},
		props: true
	},

	{
		path: 'turma/:turmaId/disciplina/:disciplinaId',
		name: 'estudantesPorTurmaNaDisciplina',
		component: (resolve) =>
			require([
				'../components/pages/schools/coordinator/estudantes-por-turma-na-disciplina.vue'
			], resolve),
		meta: {
			title: 'Alunos'
		},
		props: true
	},
	{
		path: 'apostilas/',
		name: 'createaBooks',
		component: (resolve) => require(['../components/pages/books/create-book.vue'], resolve),
		meta: {
			title: 'Apostilas'
		},
		props: true
	},
	{
		path: 'exams/upload/excel',
		name: 'examsUploadExcel',
		component: (resolve) => require(['../components/pages/exams/exams_import_excel_file.vue'], resolve),
		meta: {
			title: 'Upload Avaliacoes'
		},
		props: true
	},

	{
		path: 'testesfelipe/',
		name: 'testesFelipe',
		component: (resolve) => require(['../components/pages/tests.vue'], resolve),
		meta: {
			title: 'Testes Felipe'
		},
		props: true
	}

	// {
	// 	path: '/turma/:turmaId/disciplina/:disciplinaId',
	// 	name: 'estudantesPorTurmaNaDisciplina',
	// 	component: (resolve) => require(['../components/pages/schools/coordinator/estudantes-por-turma-na-disciplina.vue'], resolve),
	// 	meta: {
	// 		title: 'Alunos por disciplina da turma'
	// 	},
	// 	props: true
	// }
]

export default layout
