window.axios = require('axios')
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/store.js'
import VueGtag from 'vue-gtag'
import ApiService from './common/api.service'
// import EchoService from "./common/echo";
// import Notify from "./common/notification";

import _ from 'lodash'

window.$ = window.jQuery = require('jquery')
import 'bootstrap'

import VueCookies from 'vue-cookies'

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expire: '7d' })

// require("../public/vendor/summernote-0.8.18-dist/summernote.js");
// require("../public/vendor/summernote-0.8.18-dist/lang/summernote-pt-BR.js");
// import "../public/vendor/summernote-0.8.18-dist/summernote.min.css";

// https://fontawesome.com/icons
// https://medium.com/front-end-weekly/how-to-use-fon-awesome-5-on-vuejs-project-ff0f28310821
// https://github.com/FortAwesome/vue-fontawesome#installation
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fas } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// library.add(fas);
// Vue.component("font-awesome-icon", FontAwesomeIcon);

// window.axios.defaults.headers.common = {
//     'X-Requested-With': 'XMLHttpRequest'
// }

if (process.env.MIX_ENV_MODE === 'production') {
	Vue.config.productionTip = false
	Vue.config.devtools = false
	Vue.config.debug = false
	Vue.config.silent = true
}

Vue.use(_)
ApiService.init()
// if (store.state.auth) {
//   if (store.state.auth.logged == true) {
//     EchoService.init();
//   }
// }
window.toastr = require('toastr')

// https://www.npmjs.com/package/vue2-google-maps
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.MIX_GOOGLE_API_KEY,
		libraries: 'places'
	},
	installComponents: true
})

Vue.prototype.$checkPermission = function (value) {
	if (store.state.auth.userpermissions) {
		if (store.state.auth.userpermissions.includes(value)) {
			return true
		} else {
			return false
		}
	} else {
		return false
	}
}

Vue.prototype.$checkRole = function (value) {
	if (store.state.auth.user) {
		if (store.state.auth.user.role == value) {
			return true
		} else {
			return false
		}
	} else {
		return false
	}
}

const enumStatus = [
	{ key: 'novo', value: 'Nova', class: 'info' },
	{ key: 'em_elaboracao', value: 'Em Elaboração', class: 'info' },
	{ key: 'em_aplicacao', value: 'Em Aplicação', class: 'danger' },
	{ key: 'em_correcao', value: 'Em Correção', class: 'info' },
	{ key: 'indisponivel', value: 'Indisponível', class: 'secondary' },
	{ key: 'disponivel', value: 'Disponível', class: 'warning' },
	{ key: 'concluida', value: 'Concluída', class: 'success' }
]
Vue.prototype.$getStatus = function (status) {
	if (status) {
		var index = enumStatus.findIndex((item) => item.key == status)
		return enumStatus[index]
	} else {
		return { key: 'sem_status', value: 'Sem Status', class: 'danger' }
	}
}

const enumTipo = [
	{
		key: 'simulado',
		value: 'Simulado',
		class: 'info',
		article: 'o'
	},
	{
		key: 'atividaderedacao',
		value: 'Atividade - Redação',
		class: 'info',
		article: 'a'
	},
	{
		key: 'simuladoaberto',
		value: 'Simulado Aberto',
		class: 'info',
		article: 'o'
	},
	{
		key: 'prova',
		value: 'Prova',
		class: 'info',
		article: 'a'
	},
	{
		key: 'vestibular',
		value: 'Vestibular',
		class: 'info',
		article: 'o'
	}
]
Vue.prototype.$getTipo = function (tipo) {
	if (tipo) {
		var index = enumTipo.findIndex((item) => item.key == tipo)
		return enumTipo[index]
	} else {
		return null
	}
}

Vue.prototype.$getInvertedColor = function (hexcolor) {
	// https://gomakethings.com/dynamically-changing-the-text-color-based-on-background-color-contrast-with-vanilla-js/

	// If a leading # is provided, remove it
	if (hexcolor.slice(0, 1) === '#') {
		hexcolor = hexcolor.slice(1)
	}

	// If a three-character hexcode, make six-character
	if (hexcolor.length === 3) {
		hexcolor = hexcolor
			.split('')
			.map(function (hex) {
				return hex + hex
			})
			.join('')
	}

	// Convert to RGB value
	var r = parseInt(hexcolor.substr(0, 2), 16)
	var g = parseInt(hexcolor.substr(2, 2), 16)
	var b = parseInt(hexcolor.substr(4, 2), 16)

	// Get YIQ ratio
	var yiq = (r * 299 + g * 587 + b * 114) / 1000

	// Check contrast
	return yiq >= 128 ? 'black' : 'white'
}

Vue.prototype.$lightenDarkenColor = function (col, amt) {
	// https://css-tricks.com/snippets/javascript/lighten-darken-color/
	// Lighten = positive amts
	// Darken = negative amts
	var usePound = false

	if (col[0] == '#') {
		col = col.slice(1)
		usePound = true
	}

	var num = parseInt(col, 16)

	var r = (num >> 16) + amt

	if (r > 255) r = 255
	else if (r < 0) r = 0

	var b = ((num >> 8) & 0x00ff) + amt

	if (b > 255) b = 255
	else if (b < 0) b = 0

	var g = (num & 0x0000ff) + amt

	if (g > 255) g = 255
	else if (g < 0) g = 0

	return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

Vue.prototype.$backgroundColorFromText = function (str) {
	var hash = 0
	for (var i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash)
	}

	var c = (hash & 0x00ffffff).toString(16).toUpperCase()

	var color = '#' + '00000'.substring(0, 6 - c.length) + c

	// Para verificar se a cor é clara demais
	// converto para RGB
	var r, g, b, hsp, rgbcolor
	rgbcolor = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))
	r = rgbcolor >> 16
	g = (rgbcolor >> 8) & 255
	b = rgbcolor & 255

	hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

	// Using the HSP value, determine whether the color is light or dark
	if (hsp > 127.5) {
		// return 'light';
		color = this.$lightenDarkenColor(color, -50)
	} else {
		// return 'dark';
	}

	return 'background-color: ' + color + ' !important;'
}

Vue.prototype.$logout = function () {
	ApiService.post('auth/logout', {
		userId: this.user.id
	})
		.then((data) => {
			this.$store.dispatch('logout', data)
			// delete axios.defaults.headers.common["Authorization"];
			if (this.$route.name !== 'home') {
				this.$router.push({ name: 'home' })
			}
		})
		.catch((error) => {
			this.show_error = true
		})
}

const enumTipoResponsavel = [
	{ key: 'pai', value: 'Pai', show: true },
	{ key: 'Pai', value: 'Pai', show: false },
	{ key: 'mae', value: 'Mãe', show: true },
	{ key: 'Mae', value: 'Mãe', show: false },
	{ key: 'avo', value: 'Avô', show: true },
	{ key: 'avoh', value: 'Avó', show: true },
	{ key: 'tutor', value: 'Tutor', show: true },
	{ key: 'padrasto', value: 'Padrasto', show: true },
	{ key: 'madrasta', value: 'Madrasta', show: true },
	{ key: 'tio', value: 'Tio', show: true },
	{ key: 'tia', value: 'Tia', show: true },
	{ key: 'outros', value: 'Outros', show: true }
]
Vue.prototype.$getTipoResponsavel = function (type) {
	if (type) {
		var index = enumTipoResponsavel.findIndex((item) => item.key == type)
		if (index > -1) {
			return enumTipoResponsavel[index].value
		} else {
			return 'Não especificado'
		}
	} else {
		return 'Não especificado'
	}
}
Vue.prototype.$getAllTipoResponsavel = function () {
	var filtered = enumTipoResponsavel.filter(function (el) {
		return el.show == true
	})
	return filtered
}

Vue.prototype.$generateRandomString = function (length) {
	var text = ''
	var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

	for (var i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))

	return text
}

Vue.filter('formatDateTime', function (value) {
	if (value) {
		return moment(String(value)).format('DD-MM-YYYY HH:mm')
	} else {
		return null
	}
})
Vue.filter('formatEpochDateTime', function (value) {
	if (value) {
		return moment.unix(value).format('DD-MM-YYYY HH:mm:ss')
	} else {
		return null
	}
})
Vue.filter('formatDateTimeSec', function (value) {
	if (value) {
		return moment(String(value)).format('DD-MM-YYYY HH:mm:ss')
	} else {
		return null
	}
})
Vue.filter('formatDatefromNow', function (value) {
	if (value) {
		return moment(value).locale('pt-BR').fromNow()
	} else {
		return null
	}
})

Vue.filter('formatDate', function (value) {
	if (value) {
		return moment(value).format('DD-MM-YYYY')
	} else {
		return null
	}
})
Vue.filter('dateTimeFromNow', function (value) {
	if (value) {
		moment.relativeTimeThreshold('m', 45)
		return moment(value).fromNow()
	} else {
		return null
	}
})
Vue.filter('formatPercentage', function (value) {
	if (value) {
		return (value * 100).toFixed(2) + '%'
	} else {
		return null
	}
})

Vue.filter('formatDuration', function (value) {
	if (value) {
		var quotient = Math.floor(value / 60)
		var remainder = value % 60
		if (remainder > 0) {
			return quotient + 'h' + remainder + 'm'
		} else {
			return quotient + 'h'
		}
	} else {
		return null
	}
})

Vue.filter('formatMillisToMinutesAndSeconds', function (value) {
	if (value) {
		//Get hours from milliseconds
		var hours = value / (1000 * 60 * 60)
		var absoluteHours = Math.floor(hours)
		var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours

		//Get remainder from hours and convert to minutes
		var minutes = (hours - absoluteHours) * 60
		var absoluteMinutes = Math.floor(minutes)
		var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes

		//Get remainder from minutes and convert to seconds
		var seconds = (minutes - absoluteMinutes) * 60
		var absoluteSeconds = Math.floor(seconds)
		var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds

		return h + ':' + m + ':' + s
	} else {
		return null
	}
})

Vue.filter('formatCep', function (value) {
	if (value) {
		var cep = value.replace('-', '')
		return cep.substring(0, 5) + '-' + cep.substring(6, 3)
	} else {
		return null
	}
})

Vue.filter('getInitials', function (value) {
	var names = value.split(' '),
		initials = names[0].substring(0, 1).toUpperCase()

	if (names.length > 1) {
		initials += names[names.length - 1].substring(0, 1).toUpperCase()
	}
	return 'AH'
	return initials
})

Vue.getNow = function () {
	ApiService.get('/applications/getServerTime').then((response) => {
		store.commit('updateNow', {
			key: 'now',
			value: moment.tz(
				response.data.servertime,
				store.getters.servertime.format,
				store.getters.servertime.timezone
			)
		})
	})
}
// https://matteo-gabriele.gitbook.io/vue-gtag/
if (process.env.NODE_ENV === 'production') {
	Vue.use(
		VueGtag,
		{
			config: { id: 'UA-663789-19' }
		},
		router
	)
}

// https://github.com/rigor789/vue-scrollto
import VueScrollTo from 'vue-scrollto'

Vue.use(VueScrollTo, {
	container: 'body',
	duration: 500,
	easing: 'ease',
	offset: -55,
	force: true,
	cancelable: true,
	onStart: false,
	onDone: false,
	onCancel: false,
	x: false,
	y: true
})

window.moment = require('moment-timezone')

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
Vue.use(VueSweetalert2)

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/src/scss/vue-select.scss'

// https://github.com/moreta/vue-search-select#readme
import 'vue-search-select/dist/VueSearchSelect.css'

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import VueNumeric from 'vue-numeric'

Vue.use(VueNumeric)

// import MathJax from "mathjax";
// Vue.use(MathJax);

new Vue({
	el: '#app',
	router,
	store,
	render: (h) => h(App)
})
