import JwtService from '../common/jwt.service'
import ApiService from '../common/api.service'
import store from './store'

const types = {}

const getters = {}

const mutations = {}

const state = {
	userpermissions: []
}

const actions = {
	setEscolaSelecionada({ commit }, response) {
		// let data = response.data;
		// JwtService.saveToken(data.access_token);
		state.userpermissions = response
		// state.tokenagent = data.tokenagent;
	}
}

export default {
	state,
	mutations,
	getters,
	actions
}
